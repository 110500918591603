@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Montserrat:wght@400;500;600;700&family=Fira+Code:wght@400;500;600&display=swap");

/* Theme */

:root {
  /* Primary Colors */
  --primary: #0066cc;
  --primary-dark: #004c99;
  --primary-light: #4d94ff;
  --primary-ultralight: #e6f0ff;
  
  /* Secondary Colors */
  --secondary: #ff6b35;
  --secondary-dark: #e54e1b;
  --secondary-light: #ff8f66;
  --secondary-ultralight: #fff0eb;
  
  /* Accent Colors */
  --accent-1: #5d2e8c; /* Purple */
  --accent-2: #2ec4b6; /* Teal */
  --accent-3: #ffbc42; /* Gold */
  
  /* Neutral Colors */
  --white: #ffffff;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --black: #000000;
  
  /* Legacy Colors (kept for backward compatibility) */
  --orange: var(--secondary);
  --indigo: var(--accent-1);
  --light-aluminium: var(--gray-100);
  --aluminium: var(--gray-400);
  --dark-aluminium: var(--gray-800);
  --yellow: var(--accent-3);
  --mandarine: var(--secondary-light);
  --pink: #ff44dd;
  --blue: var(--primary);
  --aqua: var(--accent-2);
  --emerald: #1bc99f;
  --blueponts: #005b88;

  /* Gradients */
  --primary-gradient: linear-gradient(
    135deg,
    var(--primary) 0%,
    var(--primary-dark) 100%
  );
  
  --secondary-gradient: linear-gradient(
    135deg,
    var(--secondary) 0%,
    var(--secondary-dark) 100%
  );
  
  --accent-gradient-1: linear-gradient(
    135deg,
    var(--accent-1) 0%,
    var(--primary) 100%
  );
  
  --accent-gradient-2: linear-gradient(
    135deg,
    var(--accent-2) 0%,
    var(--accent-1) 100%
  );
  
  --accent-gradient-3: linear-gradient(
    135deg,
    var(--accent-3) 0%,
    var(--secondary) 100%
  );

  /* Legacy Gradients (kept for backward compatibility) */
  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--accent-3) -2.47%,
    var(--secondary) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--secondary-light) -2.47%,
    var(--secondary) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--accent-3) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--accent-1) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--accent-1) -2.47%,
    var(--accent-2) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--primary) -2.47%,
    var(--accent-2) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--accent-2) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--accent-3) 102.78%
  );

  /* Typography */
  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Montserrat", sans-serif;
  --font-mono: "Fira Code", monospace;
  
  /* Spacing */
  --space-1: 0.25rem;  /* 4px */
  --space-2: 0.5rem;   /* 8px */
  --space-3: 0.75rem;  /* 12px */
  --space-4: 1rem;     /* 16px */
  --space-5: 1.25rem;  /* 20px */
  --space-6: 1.5rem;   /* 24px */
  --space-8: 2rem;     /* 32px */
  --space-10: 2.5rem;  /* 40px */
  --space-12: 3rem;    /* 48px */
  --space-16: 4rem;    /* 64px */
  --space-20: 5rem;    /* 80px */
  
  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  
  /* Border Radius */
  --radius-sm: 0.125rem;  /* 2px */
  --radius: 0.25rem;      /* 4px */
  --radius-md: 0.375rem;  /* 6px */
  --radius-lg: 0.5rem;    /* 8px */
  --radius-xl: 0.75rem;   /* 12px */
  --radius-2xl: 1rem;     /* 16px */
  --radius-3xl: 1.5rem;   /* 24px */
  --radius-full: 9999px;
  
  /* Transitions */
  --transition-fast: 150ms;
  --transition: 200ms;
  --transition-slow: 300ms;
  --ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --ease-in: cubic-bezier(0.4, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
}
