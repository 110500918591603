.tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab {
  padding: 0.5rem 1rem;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
}

.tab.active {
  background: #007bff;
  color: white;
}

.tab-content {
  padding: 1rem;
}

.tab-pane {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-button {
  padding: 0.5rem 1rem;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-item {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-item > * {
  padding: 0 0.5rem;
}

.delete-button {
  padding: 0.5rem 1rem;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-form {
  margin: 20px 0;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 4px;
}

.admin-form input,
.admin-form textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.admin-form textarea {
  min-height: 100px;
}

.form-buttons {
  display: flex;
  gap: 10px;
}

.form-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.admin-actions {
  margin: 2rem 0;
  display: flex;
  gap: 1rem;
}

.admin-actions__button {
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #3b82f6;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.admin-actions__button:hover {
  background-color: #2563eb;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-actions__button:active {
  transform: translateY(0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.admin-actions__button:last-child {
  margin-right: 0;
}