.grid-layout__content {
  flex: 1;
  width: 100%;
  padding-top: 3rem;
}

.members-grid {
  display: flex !important;
  flex-wrap: wrap !important;
}

@media only screen and (max-width: 640px) {
  .grid-layout__content {
    padding-top: 6.4rem;
  }
}
