app-nav-bar {
  width: 100%;
}

app-nav-bar-tabs {
  flex: 1;
}

app-nav-bar-tab {
  margin-right: 14px;
}

app-nav-bar-tab:last-child {
  margin-right: 0;
}

app-login-button,
app-signup-button,
app-logout-button {
  margin-right: 1.6rem;
}

app-login-button:last-child,
app-signup-button:last-child,
app-logout-button:last-child {
  margin-right: 0;
}

app-page-loader {
  display: flex;
  height: 100%;
  width: 100%;
}

app-mobile-nav-bar {
  width: 100%;
}

app-mobile-nav-bar-tab {
  width: 100%;
}

app-mobile-nav-bar-brand {
  flex: 1;
}

