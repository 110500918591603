.members-container {
  width: 100%;
  margin: 0 auto;
}

.members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  padding: 1rem;
}

.member-card {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.member-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.member-info h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
  margin-top: 0;
}

.member-details {
  font-size: 1.125rem;
  color: #4b5563;
  line-height: 1.6;
}

.member-details p {
  margin: 0.25rem 0;
}

/* Search input styling */
.search-input {
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  width: 100%;
  max-width: 28rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.search-input::placeholder {
  color: #9ca3af;
  font-size: 1.25rem;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
}

.pagination-button {
  background-color: var(--white);
  border: 1px solid var(--blueponts);
  color: var(--blueponts);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.pagination-button:hover:not(:disabled) {
  background-color: var(--blueponts);
  color: var(--white);
}

.pagination-button:disabled {
  border-color: var(--aluminium);
  color: var(--aluminium);
  cursor: not-allowed;
}

.pagination-info {
  font-size: 1rem;
  color: var(--dark-aluminium);
  font-weight: 500;
}
