/* Modern CSS Reset and Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
@import "theme.css";
@import "general.css";
@import "components/index.css";

/* Modern CSS Features */
@layer base {
  :root {
    color-scheme: light;
  }
  
  ::selection {
    background-color: var(--primary-light);
    color: var(--white);
  }
  
  /* Improved scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: var(--gray-100);
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--gray-400);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-500);
  }
}
