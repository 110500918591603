.page-footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  width: 100%;

  margin-top: 2.4rem;

  background-color: var(--blueponts);

  color: var(--white);
  font-weight: 500;
  text-align: center;

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.page-footer-grid {
  display: grid;
  grid-template-rows: auto auto;

  width: 100%;
  max-width: 120rem;

  /* responsive */

  padding: 3.2rem;
}

.page-footer-grid__info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4.8rem;
  justify-content: center;
}

.page-footer-grid__brand {
  display: grid;

  justify-items: center;

  grid-template-columns: 1fr;
  margin-top: 3.2rem;
}

.page-footer-info__message {
  /* responsive */

  text-align: left;
}

.page-footer-message__headline {
  margin: 0 0 1.4rem;

  font-weight: 500;
}

.page-footer-message__description {
  margin-bottom: 0;

  letter-spacing: 0.016rem;
}

.page-footer-info__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-footer-info__resource-list {
  display: grid;
  row-gap: 0.8rem;
}

.page-footer-info__resource-list-item {
  text-align: center;
}

.page-footer-info__resource-list:first-child .page-footer-info__resource-list-item {
  text-align: center;
}

.page-footer-info__resource-list-item.active {
  font-weight: bold;
  /* Add any other styling you want for the active state */
}

.page-footer-brand {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.page-footer-brand__logo {
  width: 100px;
  margin-right: 12px;
}

.page-footer__hyperlink {
  letter-spacing: 0.001rem;
}

.page-footer__hyperlink,
.page-footer__hyperlink:active,
.page-footer__hyperlink:visited {
  color: var(--white);
}

.page-footer__hyperlink:hover {
  color: var(--aluminium);
}

@media only screen and (max-width: 960px) {
  .page-footer {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .page-footer-grid {
    /* responsive */

    padding: 3.2rem;
  }

  .page-footer-grid__info {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .page-footer-info__message {
    /* responsive */

    text-align: center;
  }

  .page-footer-info__resource-list {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }

  .page-footer-info__resource-list-item,
  .page-footer-info__resource-list:first-child .page-footer-info__resource-list-item {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .page-footer {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .page-footer-grid {
    /* responsive */

    padding: 3.2rem 1.6rem;
  }

  .page-footer-grid__info {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .page-footer-info__message {
    /* responsive */

    text-align: center;
  }

  .page-footer-info__resource-list {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }

  .page-footer-info__resource-list-item,
  .page-footer-info__resource-list:first-child .page-footer-info__resource-list-item {
    text-align: center;
  }
}

.page-footer-copyright {
  text-align: center;
  color: var(--aluminium);
  font-size: 1.2rem;
  margin-top: 2.4rem;
  padding-top: 1.6rem;
  border-top: 1px solid var(--aluminium);
}

.page-footer-copyright__separator {
  margin: 0 0.8rem;
}

.page-footer-copyright__email {
  color: var(--aluminium);
  text-decoration: none;
}

.page-footer-copyright__email:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 480px) {
  .page-footer-copyright {
    margin-top: 1.6rem;
    padding-top: 1.2rem;
  }
}
