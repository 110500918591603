/* General */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; /* 10px base for easier rem calculations */
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: var(--white);
  color: var(--gray-800);
  font-family: var(--font-primary);
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  overflow-x: hidden;
  overflow-y: auto;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-secondary);
  font-weight: 700;
  line-height: 1.2;
  color: var(--gray-900);
  margin-bottom: var(--space-4);
}

h1 {
  font-size: 4rem;
  letter-spacing: -0.02em;
  margin-top: var(--space-8);
}

h2 {
  font-size: 3.2rem;
  letter-spacing: -0.01em;
  margin-top: var(--space-8);
}

h3 {
  font-size: 2.6rem;
  margin-top: var(--space-6);
}

h4 {
  font-size: 2.2rem;
  margin-top: var(--space-6);
}

h5 {
  font-size: 1.8rem;
  margin-top: var(--space-4);
}

h6 {
  font-size: 1.6rem;
  margin-top: var(--space-4);
}

p {
  margin-bottom: var(--space-4);
  max-width: 70ch; /* Improves readability */
}

strong {
  font-weight: 600;
}

small {
  font-size: 1.4rem;
}

blockquote {
  padding: var(--space-4) var(--space-6);
  margin: var(--space-6) 0;
  border-left: 4px solid var(--primary);
  background-color: var(--primary-ultralight);
  border-radius: var(--radius);
  font-style: italic;
  color: var(--gray-700);
}

/* Form elements */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Links */
a {
  color: var(--primary);
  text-decoration: none;
  transition: color var(--transition) var(--ease-in-out);
}

a:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

a.underlined {
  text-decoration: underline;
}

/* Images */
figure {
  margin: var(--space-4) 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: var(--radius);
}

/* Code */
code,
pre {
  font-family: var(--font-mono);
  width: 100%;
  border-radius: var(--radius);
}

code {
  color: var(--accent-1);
  background-color: var(--gray-100);
  padding: 0.2rem 0.4rem;
  border-radius: var(--radius-sm);
  font-size: 0.9em;
}

pre {
  background-color: var(--gray-900);
  color: var(--gray-100);
  padding: var(--space-4);
  overflow-x: auto;
  margin: var(--space-4) 0;
}

pre code {
  color: inherit;
  background-color: transparent;
  padding: 0;
}

/* Root element */
#root {
  height: 100%;
  width: 100%;
}

/* Layout */
.container {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 var(--space-4);
}

.section {
  padding: var(--space-12) 0;
}

/* Content page */
.content-page {
  max-width: 80rem;
  margin: 0 auto;
  padding: var(--space-8);
  position: relative;
  z-index: 1;
}

/* Page Title Styling */
.page-title {
  position: relative;
  font-size: 4rem;
  font-weight: 700;
  color: var(--gray-900);
  margin-bottom: var(--space-10);
  padding-bottom: var(--space-4);
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.page-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 8rem;
  height: 0.4rem;
  background: var(--primary-gradient);
  border-radius: var(--radius-full);
}

.page-title-centered {
  text-align: center;
}

.page-title-centered::after {
  left: 50%;
  transform: translateX(-50%);
}


.bg-logo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../public/images/logo.jpg');
  background-size: 83% auto;
  background-position: top center;
  background-repeat: no-repeat;
  opacity: 0.05;
  z-index: -1;
  filter: saturate(1.2);
}

.content-page p {
  margin-bottom: var(--space-4);
}

/* Cards */
.card {
  background-color: var(--white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow);
  overflow: hidden;
  transition: transform var(--transition) var(--ease-out), 
              box-shadow var(--transition) var(--ease-out);
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-md);
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-2) var(--space-4);
  border-radius: var(--radius);
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all var(--transition) var(--ease-in-out);
  border: none;
  outline: none;
}

.btn-primary {
  background-color: var(--primary);
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  color: var(--white);
  text-decoration: none;
}

.btn-secondary {
  background-color: var(--secondary);
  color: var(--white);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark);
  color: var(--white);
  text-decoration: none;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.btn-outline:hover {
  background-color: var(--primary);
  color: var(--white);
  text-decoration: none;
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: var(--space-2) var(--space-4);
  margin-bottom: var(--space-6);
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-radius: var(--radius);
  font-weight: 500;
  cursor: pointer;
  transition: all var(--transition) var(--ease-in-out);
  border: none;
  box-shadow: var(--shadow-sm);
}

.back-button:hover {
  background-color: var(--gray-200);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  text-decoration: none;
}

/* Utilities */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: var(--space-2);
}

.gap-4 {
  gap: var(--space-4);
}

.gap-8 {
  gap: var(--space-8);
}

.mt-4 {
  margin-top: var(--space-4);
}

.mb-4 {
  margin-bottom: var(--space-4);
}

.my-4 {
  margin-top: var(--space-4);
  margin-bottom: var(--space-4);
}

.p-4 {
  padding: var(--space-4);
}

/* Responsive */
@media (max-width: 768px) {
  html {
    font-size: 56.25%; /* 9px base */
  }
  
  h1 {
    font-size: 3.6rem;
  }
  
  h2 {
    font-size: 2.8rem;
  }
  
  h3 {
    font-size: 2.4rem;
  }
  
  .content-page {
    padding: var(--space-4);
  }
  
  .mobile-scroll-lock {
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 50%; /* 8px base */
  }
  
  h1 {
    font-size: 3.2rem;
  }
  
  h2 {
    font-size: 2.6rem;
  }
  
  h3 {
    font-size: 2.2rem;
  }
}
